// @import '../../sass/colors.scss';
// @import '../../sass/mixins.scss';

.info-card-container {
  margin-bottom: 40px !important;
  justify-content: center;
}

.info-card > * {
  padding: 4px 20px;
  width: calc(100% - 40px) !important;
}

.info-card h4 {
  font-weight: 600;
  font-size: 20px;
  font-family: "SF Pro Text";
}

.info-card .button {
  padding: 0;
  margin: 10px 20px;
  margin-top: 40px;
  @include media(only-tablet) {
    min-width: 130px;
    width: auto;
  }
  @include media(phone) {
    margin-top: 20px;
    margin-bottom: 36px;
  }
}

.info-card-container .info-card {
  &:nth-child(2),
  &:nth-child(3) {
    border-right: 1px solid rgba(255, 212, 163, 0.5);
    border-left: 1px solid rgba(255, 212, 163, 0.5);
    @include media(phone) {
      border: none;
    }
  }
}
