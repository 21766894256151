.container.benefit.simple {
  @include media(phone) {
    display: none;
  }
}

.benefit.full.loading {
  position: absolute;
  top: 100px;
  left: 0px;
}

.benefit-card li {
  max-height: 185px;
  min-height: 170px;
  @include media(phone) {
    max-height: none;
    width: 100%;
  }
}

.benefit-card-container {
  margin-top: 30px;
  @include media(phone) {
    h4 {
      margin-bottom: 5px;
    }
  }
}

.container.benefit-card ul {
  padding: 0 0 0 20px;
  @include media(phone) {
    padding: 0px;
  }
}
.container.benefit-card-simple ul {
  padding: 0px;
}

.benefit-image {
  width: $cardWidth;
  height: $benefitCardHeight;
  border-radius: 2px;
  margin-right: 25px;
  @include media(phone) {
    margin: 0 0 15px 0;
    height: 170px;
  }
}

.benefit-description {
  max-width: 425px;

  .description-text {
    margin-bottom: 6px;
  }
}

.benefit-simple.benefit {
  a {
    text-align: center;
  }
}

.benefit-simple .benefit-image {
  margin-right: 0px;
  margin-bottom: 8px;
}

.benefit p {
  margin-bottom: 0;
  margin-top: 0;
}

.benefit-simple,
.benefit-card a {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.benefit-card-simple {
  li {
    margin-bottom: 20px;
  }
}
