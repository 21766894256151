.side-bar {
  width: $sidebarWidth;
  min-width: $sidebarWidth;
  display: none;
  @include media(break-menu-screen) {
    display: block;
  }
}

.container-nav {
  height: auto;
  top: 80px;
  position: sticky;
}

.side-bar .container-nav {
  width: inherit;
}

nav li {
  width: 100%;
  height: 40px;
  margin: 5px 0;

  span {
    margin-left: 30px;
  }
}

nav a {
  display: block;
  height: 100%;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.side-bar span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.side-bar .active span {
  border-bottom: 3px solid $color-light-gold;
  padding: 0 15px 3px 0;
  transition: 0.2s all;
}
