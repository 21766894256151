.insurances.container li {
  width: 100%;
}

// simple

.insurance-card-simple {
  margin-bottom: 20px;
  @include media(phone) {
    background-color: $color-insurance-card-simple-background;
    padding: 10px 0;
  }
}

.insurance-card-simple h4 {
  font-weight: 500;
  font-family: "SF Pro Text";
  font-size: 20px;
}

.insurance-card-simple .insurance-icon {
  font-size: 55px;
}

.insurance-card-simple .chevron {
  margin-left: 10px;
  font-size: 15px;
  margin-top: 2px;
  font-weight: 400;
}

.insurance-card-simple .insurance-name.flex {
  width: auto;
}

.insurance-card .insurance-name h4 {
  font-weight: 300;
}

.insurance-card-simple .button {
  max-width: 232px;
  margin-right: 10px;
  @include media(phone) {
    margin-right: 0;
  }

  @include media(phone) {
    &:last-child {
      margin-bottom: 10px;
    }
    width: 100%;
    max-width: 80%;
  }
}

// regular
.insurance-card {
  border-radius: 2px;
  background-color: $color-primary-card-background;
  margin-bottom: 50px;
  padding: 20px;
  @include media(phone) {
    padding: 0;
    background-color: transparent;
  }
}

.insurance-card.flex {
  width: calc(100% - 40px);
  @include media(phone) {
    width: 100%;
  }
}
.insurance-card .button {
  max-width: 220px;
}
.insurance-card .button span {
  padding: 0 10px;
}
.insurance-card .text-block div:first-child {
  max-width: 67%;
  @include media(phone) {
    max-width: 100%;
  }
}

.insurance-card .button {
  @include media(phone) {
    max-width: 100%;
  }
}

.insurance-card .text-block div:last-child {
  width: 33%;
  min-width: 174px;
  @include media(phone) {
    width: 100%;
  }
}
.insurance-card i {
  margin-left: 10px;
}
.insurance-card .insurance-icon {
  font-size: 50px;
}
.insurance-card .insurance-description {
  margin-bottom: 30px;
  @include media(phone) {
    margin-bottom: 10px;
  }
}
.insurance-divider {
  background-color: $color-insurance-divider;
}
.insurance-terms,
.co-insurance-terms {
  white-space: nowrap;
  a {
    font-size: 16px;
    color: $color-insurance-terms;
    font-weight: 400;
    @include media(phone) {
      font-size: 14px;
    }
  }
}

.co-insurance-terms {
  a {
    font-size: 14px;
    font-weight: 400;
    @include media(phone) {
      margin-left: 4px;
    }
  }
}
.insurance-terms i,
.co-insurance-terms i {
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
}

.co-insurance-terms i {
  font-size: 10px;
}
.insurance-card .co-insured-border {
  border-left: 2px solid $color-co-insured-border;
}
.insurance-card .un-co-insured-border {
  border-left: 2px solid $color-un-co-insured-border;
}

.insurance-card .co-insured-border,
.insurance-card .un-co-insured-border {
  min-height: 24px;
  @include media(phone) {
    height: auto;
    min-height: 30px;
  }
}

.co-insured-border .label-container:first-child,
.un-co-insured-border .label-container:first-child {
  margin-left: 10px;
}

.un-co-insured-border {
  @include media(phone) {
    flex-wrap: wrap;
  }
}

.un-co-insured-border.row-container {
  @include media(phone) {
    margin-bottom: 40px;
  }
}
.co-insured-border.row-container {
  @include media(phone) {
    margin-bottom: 20px;
  }
}
.un-co-insured-border .label-container {
  @include media(phone) {
    &:first-child,
    &:nth-child(2) {
      width: auto;
    }
    &:last-child {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}

.align-second-end-phone div:nth-child(2) {
  @include media(phone) {
    align-items: flex-end;
  }
}
.align-second-start-phone div:nth-child(2) {
  @include media(phone) {
    align-items: flex-start;
  }
}
.align-second-end-phone:last-of-type {
  @include media(phone) {
    flex-direction: column !important;
  }
}
.insurance-card-simple-full-width ul {
  @include media(phone) {
    margin: 0 -30px 0 -30px !important;
    width: calc(100% + 60px) !important;
  }
}

.insurance-card {
  .align-second-end-phone {
    margin-bottom: 0;
  }
}
.insurance-letters_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.insurnace-letters_link {
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #7993b0;
  font-weight: 400;
  font-family: "Open Sans";
  @include media(phone) {
    font-size: 14px;
    padding: 0;
  }
}
.insurance-letters_content .align-second-end-phone div:nth-child(2) {
  @include media(phone) {
    align-items: center;
  }
}
.insurance-letters_content {
  display: flex;
  margin: 5px 0;
  align-items: center !important;
}
.insurance-spinner {
  @include media(phone) {
    margin-top: -35%;
  }
}
.pdf-logo {
  margin-right: 5px;
  height: auto;
  max-width: 100%;
  display: flex;
}
.insurance-letters_date {
  margin-left: 5px;
}
.keytabs_wrapper {
  width: calc(100% - 20px);
}
.summation_wrapper {
  width: calc(100% - 20px);
}
.insurance-letters_image-container {
  width: 20px;
}
