.vehicle-promo-banner {
  @include media(phone) {
    margin: 0 -30px 0 -30px;
    width: calc(100% + 60px);
  }
}

.vehicle-promo-banner h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  color: #4f555c;
  align-self: flex-start;
  @include media(phone) {
    align-self: center;
  }
}

.vehicle-promo-banner .banner {
  margin-bottom: 0px;
}

.inline-icon {
  font-size: 120px !important;
}
