
#primary-nav {
    height: 44px;
    color: $color-primary-blue;
    background: $color-white;
    position: relative;
    z-index: 500;
    top: 70px;
    box-shadow: 0px 5px 14px -5px rgb(0, 0, math.div(0, 0.5));
    .nav-container {
        display: flex;
        justify-content: flex-end;
        height: 44px;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
    }
    nav {
        background-image: none;
        background-color: #fff;
        position: relative;
        height: 44px;
    }
    li {
        height: 39px;
        position: relative;
        margin: auto;
        display: flex;
        color: #4f555c;
        list-style-type: none;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.7;
        letter-spacing: normal;
        border-top: 5px solid transparent;
        transition: 0.2s;

        @media screen and (min-width: 768px) {
            &:hover {
                border-top: 5px solid $color-light-gold;
            }
        }
    }
    .primary-menu {
        position: relative;
        align-items: baseline;
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 100%;
        justify-content: space-around;
        box-sizing: border-box;
        height: 44px;
    }
    nav li:not(:last-of-type) {
        margin-right: 40px !important;
    }
    a {
        color: #324050;
        text-transform: uppercase;
        padding: 9px 5px 14px 5px;
        font-size: 11px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.7px;
        white-space: nowrap;
        text-decoration: none;
        height: auto;
    }
    @include media(tablet) {
        display: none;
    }
}
