.direct-debit-banner h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 10px;
  color: #4f555c;
  align-self: flex-start;
  max-width: 80%;
  @include media(phone) {
    align-self: center;
    text-align: center;
    margin-bottom: 15px;
    width: 80%;
    max-width: unset;
  }
}

.banner-span {
  @include media(phone) {
    text-align: center;
    font-size: 15px;
  }
}

.direct-debit-banner a.button {
  padding: 0 15px;
}

.direct-debit-banner .children-content {
  margin: 30px 0 20px 80px;
  @include media(phone) {
    margin: 30px 0 20px 0;
  }
}

.direct-debit-banner .banner {
  padding: 0px;
}
