.vehicle-card {
  border-radius: 10px;
  height: 80%; //IE
  margin-bottom: 20px;
  min-width: 16px;
  min-height: 240px;
  padding: 8px 10px;
  width: calc(100% - 20px);

  @include media(phone) {
    justify-content: center;
    align-items: center;
  }
}

.vehicle-background-image {
  height: 100px;
  width: 100%;
  @include media(phone) {
    height: 85px;
    width: 100%;
    align-items: center;
  }
}

.vehicle-regnr {
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0;
  font-family: "SF Pro Text";
}

.vehicle-info-text {
  color: #1c1c1c;
  flex-grow: 1;
  font-size: 16px;
  white-space: pre-line;
  @include media(tablet) {
    margin-bottom: 20px;
  }
}

.vehicle-button {
  max-width: 100%;
  min-width: auto;
}

.vehicle-icon {
  font-size: 120px;
}

.vehicle-card a {
  width: 100%;
}
