input {
  width: 100%;
  height: 29px;
  border-radius: 5px;
  border: 1px solid #c7cace;
  background-color: #fff;
  margin-bottom: $inputMarginBottom;
  text-indent: 10px;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

input::-webkit-input-placeholder {
  line-height: 29px !important;
}

input::-moz-placeholder {
  line-height: 29px !important;
}

.input-container {
  width: 100%;
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
span.form-label {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  margin-top: 0px;
  color: $color-primary-form-label;
}

.error-message {
  position: absolute;
  left: 0;
  bottom: -7px;
  color: red !important;
  font-size: 10px;
  height: 14px;
  font-weight: 500 !important;
  @include media(phone) {
    bottom: -4px;
  }
}

.form-label-container {
  font-weight: bold;
  color: $color-secondary-text;
}

input::placeholder {
  @include font-normal(15px);
  color: $color-primary-placeholder;
}
