#menu-keyTabs .MuiPaper-root-690 {
  background-color: $color-white;
}
.modal {
  .modal.content {
    overflow: auto;
    border-radius: 5px;
    background-color: $color-modal-background;
    padding: 30px 35px;
    max-width: 600px;
    margin: 10px;
    max-height: 90vh;
    -webkit-overflow-scrolling: touch;
  }

  @media only screen and (max-width: 600px) {
    .modal.content {
      padding: 17px 15px;
    }
  }

  .co-insurance-component {
    margin-top: 15px;
  }

  .co-insurance-no {
    .input-container {
      height: 70px;
    }
    .error-message {
      bottom: -2px;
    }
  }
  i {
    cursor: pointer;
    margin: 1px 0 0 5px;
  }

  .disclaimer-text {
    font-style: italic;
    font-size: 12px;
  }
  .co-insured-price {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .co-insured-price-heading {
    font-weight: 600;
  }
  .co-insured-price-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: pre-wrap;
    font-size: 14px;
  }
}

.modal.content.bank-id {
  background-color: $color-white;
  .dialog-title {
    width: 100%;
    margin: 0 auto;

    img {
      height: 120px;
    }
  }
  .dialog-content span {
    text-align: center;
    margin-bottom: 20px;
    color: $color-primary-text;
  }

  .open-app-content {
    a {
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
    }
    span {
      font-size: 14px;
      margin-bottom: 1px;
    }
  }
}

.modal.content.new-vehicle {
  background-color: $color-white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba($color-dark, 0.1);
  margin-bottom: 40px !important;
  box-shadow: 1px 2px rgba($color-dark, 0.1);

  .dialog-title {
    color: rgba(50, 64, 80, 1);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
  }
  .dialog-content span {
    color: rgba(50, 64, 80, 1);
    font-weight: 600;
  }

  .open-app-content {
    a {
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
    }
    span {
      font-size: 14px;
      margin-bottom: 1px;
    }
  }
}

.coInsuredModal-button {
  margin-top: 25px;
  margin-bottom: 20px;
  @include media(phone) {
    .button {
      margin-top: 8px;
    }
  }
}

.dialog-title {
  color: $color-white;
}

.dialog-content {
  padding: 20px 0;
  span:not(.submit-error),
  label {
    color: $color-white;
  }
}

.dialog-content.dialog-form {
  padding-bottom: 0px;
}

.is-co-insured input:-webkit-autofill:disabled,
.is-co-insured input:-webkit-autofill:hover:disabled,
.is-co-insured input:-webkit-autofill:focus:disabled,
.is-co-insured input:-webkit-autofill:active:disabled {
  -webkit-box-shadow: 0 0 0 30px $color-modal-background inset;
  box-shadow: 0 0 0 30px $color-modal-background inset;
  -webkit-text-fill-color: $color-white;
  border-radius: 0px;
}

.dialog-form input:disabled {
  background-color: $color-modal-background;
  color: $color-white;
}

.content-description {
  display: inline-block;
  margin-bottom: 20px;
}

.dialog-actions.flex {
  @include media(phone) {
    align-items: center;
  }
}

.dialog-actions .button {
  @include media(phone) {
    min-width: 100%;
  }
}

.select.input {
  margin: 0 10px;

  :first-child {
    padding-left: 2px;
  }
}

.coInsuredModal-text .initial-form {
  margin-top: 10px;
}
