.container.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: space-between;
    @include media(tablet) {
      flex-direction: column;
    }
  }
  > .button {
    margin-top: 35px;
    width: 216px;
    padding: 5px;
  }
}
