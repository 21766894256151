.login {
  .content {
    max-width: $loginMaxWidth + 100px;
    width: 100%;
  
    > span {
      margin-bottom: 30px;
    }
  }

  .heading {
    margin-bottom: 10px;
    @include media(desktop) {
      display: none;
    }
  }

  .border {
    max-width: 120px;
  }
}