$remainingHamburgerWidth: 100% - $hamburgerWidth;

.hamburger,
.hamburger-content {
  @include media(break-menu-screen) {
    display: none;
  }
  width: auto;
}

.hamburger-name {
  font-size: 11px;
  font-family: "SF Pro Text", "Arial";
  color: $color-white;
}

.hamburger-line {
  display: block;
  height: 1px;
  width: 16px;
  background-color: $color-white;
  margin: 5px auto;
}

.menu2-menu-item .hamburger-line,
.menu2-content-wrapper .hamburger-line {
  margin: 5px auto 0 auto;

  &:first-of-type {
    margin-top: 8px;
  }
}

.hamburger-content span {
  color: $color-white;
}

.hamburger-content {
  position: absolute;
  right: -100%;
  top: 0;
  width: $hamburgerWidth;
  height: 100%;
  background-image: linear-gradient(
    202deg,
    $color-hamburger-background-stop1,
    $color-hamburger-background-stop2
  );
  z-index: 2001;
  box-shadow: -7px 2px 18px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
}
.hamburger-content.open {
  right: 0;
}
.hamburger-content hr {
  max-width: 100%;
}
.hamburger-inner-content {
  overflow: auto;
  height: 100%;
  padding: 15px 20px 20px 30px;
  width: calc(100% - (50px + #{$remainingHamburgerWidth}));
}
body.hamburger-open {
  @include media(small-desktop) {
    overflow-y: hidden;
  }
}
.hamburger-content .nav-link.logout {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}
.hamburger-content .nav-link i {
  font-size: 18px;
  margin: 0 0 0 8px;
}
.hamburger-content .content-wrapper {
  margin-bottom: 50px;
}
// Icon
.hamburger-icon {
  width: 30px;
  height: fit-content;
  cursor: pointer;
}
.hamburger-content .hamburger-icon {
  margin: 0 0 0 auto;
  padding-top: 2px;
}
.hamburger-icon.show-menu .hamburger-name {
  display: inherit;
  margin-top: -2px;
}
// Icon animation
.hamburger-icon span {
  transition-duration: 0.5s;
}
.hamburger-icon.show-menu span:nth-child(1) {
  -webkit-transform: translateY(1px) rotate(45deg);
  -ms-transform: translateY(1px) rotate(45deg);
  -o-transform: translateY(1px) rotate(45deg);
  transform: translateY(1px) rotate(45deg);
}
.hamburger-icon.show-menu span:nth-child(2) {
  opacity: 0;
}
.hamburger-icon.show-menu span:nth-child(3) {
  -webkit-transform: translateY(-11px) rotate(-45deg);
  -ms-transform: translateY(-11px) rotate(-45deg);
  -o-transform: translateY(-11px) rotate(-45deg);
  transform: translateY(-11px) rotate(-45deg);
}

.hamburger-nav span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-left: 0px;
}
.hamburger-nav .active span {
  position: relative;
}
.hamburger-nav .active span:before {
  content: "";
  background-color: #ffd4a3;
  position: absolute;
  left: -30px;
  display: inline-block;
  width: 5px;
  height: 28px;
  top: -4px;
}

.menu2-content-wrapper {
  z-index: 9000;
  display: none;

  @include media(tablet) {
    display: block;
  }

  .hamburger-inner-content {
    padding: 0px 20px 20px 20px;
    height: auto;
  }

  .content-wrapper {
    height: 100%;
  }

  .container-nav {
    top: 0;
  }
  
  li, li a, li a span {
    font-size: 14px;
    font-weight: 400;
  }

  a {
    display: inline;
  }

  .hamburger-icon {
    margin: 0 0 5px 10px;
    position: fixed;
    right: 20px;
    top: 4px;
    z-index: 8000;

    @media screen and (min-width: 601px) {
      top: 8px;
    }
    
    &.show-menu {
      display: block;
      top: 11px;

      @media screen and (min-width: 601px) {
        top: 15px;
      }
    }
  
    .hamburger-name {
      margin-top: 0;
    }
  }
}

.menu2-top-row {
  @include media(tablet) {
    position: fixed;
    right: 60px;
  }
}

.menu2-menu-item {
  display: none;

  @include media(tablet) {
    display: block;
    margin: 0 0 5px 10px;
    position: fixed;
    right: 20px;
    top: 4px;
    z-index: 8000;
  }
}