.details.form {
  margin-top: 35px;
  flex-wrap: wrap;
  justify-content: flex-start;
  @include media(tablet-only) {
    padding: 0 40px;
  }
  @include media(phone) {
    width: 100%;
    padding: 0;
  }

  .inner-container {
    width: 50%;
  }
  .inner-container:first-child {
    max-width: 50%;
    width: 100%;
    @include media(phone) {
      max-width: 100%;
    }
  }
  #form .input-container{
    width: 50%;
    flex-wrap: wrap;
  }

  // #form .input-container:first-child {
  //   max-width: calc(65% - 50px);
  //   width: auto;
  //   margin-right: 50px;
  //
  //   @include media(phone) {
  //     width: 100%;
  //   }
  // }
  // .inner-container:last-child,
  // #form .input-container:nth-child(2) {
  //   width: 35%;
  //
  //   @include media(phone) {
  //     width: 100%;
  //   }
  // }
}
.details-form .flex{
  flex-wrap: wrap;
}
