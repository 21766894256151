$break-small: 600px;
$break-medium: 900px;
$break-menu: 1050px;
$break-large: 1300px;

@mixin media($device) {
  @if $device == phone {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $device == tablet {
    @media only screen and (max-width: $break-medium + 1) {
      @content;
    }
  } @else if $device == only-tablet {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium) {
      @content;
    }
  } @else if $device == desktop {
    @media only screen and (min-width: $break-medium + 1) {
      @content;
    }
  } @else if $device == only-small-desktop {
    @media only screen and (min-width: $break-medium + 1) and (max-width: $break-large) {
      @content;
    }
  } @else if $device == small-desktop {
    @media only screen and (max-width: $break-large) {
      @content;
    }
  } @else if $device == break-menu-screen {
    @media only screen and (min-width: $break-menu + 1) {
      @content;
    }
  } @else if $device == break-xl {
    @media only screen and (max-width: $break-large + 1) {
      @content;
    }
  } @else if $device == xl-screen {
    @media only screen and (min-width: $break-large + 1) {
      @content;
    }
  }
}

@mixin flex {
  display: flex;
  width: 100%;
  @include media(phone) {
    flex-direction: column;
    align-items: flex-start;
  }
}

@mixin flex-column {
  @include flex;
  flex-direction: column;
}

@mixin normal {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin font-normal($size) {
  @include normal;
  font-family: 'Open Sans';
  color: $color-primary-label;
  font-size: $size;
}

@mixin font-heading($size) {
  @include normal;
  color: $color-primary-heading;
  font-size: $size;
  font-family: 'SF Pro Display', 'Open Sans';
}

@mixin button {
  max-width: 174px;
  min-width: 150px;
  height: 44px;
  border-radius: 7px;
  border: none;
  padding: 0px;
  outline: none;
  cursor: pointer;
}

@mixin a($color) {
  @include font-normal(12px);
  font-weight: 600;
  text-decoration: none;
  color: $color;
  outline: none;
  border: none;
  cursor: pointer;
}

@mixin span($color) {
  @include font-normal(14px);
  color: $color;
}

@mixin container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

@mixin absolute($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}
