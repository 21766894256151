.form-card {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba($color-dark,0.1);
  margin-bottom: 40px !important;
  background-color: white;
  box-shadow: 1px 2px rgba($color-dark,0.1);
  h4{
    color: rgba(50,64,80,1);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
  }
  p{
    color: rgba(50,64,80,1);
    font-weight: 600;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.disabled{
  opacity: 0.2;
  pointer-events:none;
}