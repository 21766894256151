.claim {
  padding: 40px;
  background-color: #f5f9fc;

  @media (max-width: 768px) {
    padding: 5px;
  }
}

.radio-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -10px;
}

.submit-button {
  button {
    text-transform: uppercase;
    font-family: "Open Sans";
    font-weight: 400;
    display: block;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: #f6d4b1;
    border: 1px solid rgba($color-dark, 0.1);
    box-shadow: none;
    width: 20%;
    padding: 10px;
    &:hover {
      background-color: #f5c494;
      cursor: pointer;
    }
  }
}

.textarea-container {
  textarea {
    display: block;
    margin: 20px auto 10px auto;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba($color-dark, 0.1);
    box-shadow: 1px 2px rgba($color-dark, 0.1);
    font-size: 0.8em;
    font-family: "Open Sans";
    resize: none;
  }
}

.date-time-select-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.input-date-claims {
  width: 180px;
  border: 1px solid rgba($color-dark, 0.1);
}

.input-time-claims {
  width: 180px;
  border: 1px solid rgba($color-dark, 0.1);
}

.damage-type-select-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.damage-type-select {
  border-radius: 5px;
  border: 1px solid rgba($color-dark, 0.1);
  padding: 5px;
  font-family: "Open Sans";
  font-weight: 400;
  height: 30px;
  width: 180px;
}

.damage-type-warning {
  display: block;
  font-style: italic;
  color: black;
  font-size: x-small;
  text-align: start;
}

.input-claim-cost {
  width: 100px;
  padding-right: 15px;
  border: 1px solid rgba($color-dark, 0.1);
}

.input-claim-bank {
  width: 95%;
  padding-right: 15px;
  border: 1px solid rgba($color-dark, 0.1);
}

.claim-insurance-information{
  display: inline-flex;
  margin: 0 0 8px 0;
  span {
    margin: auto 5px auto 5px;
    font-weight: bold;
  }
}

.suffix-cost {
  top: 19px;
  margin-left: -20px;
}

.button-add-claim-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 90px;
  padding: 5px;
  color: white;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: smaller;
  font-weight: 400;
  border-radius: 5px;
  background-color: #324050;
  border: 1px solid rgba($color-dark, 0.1);
  box-shadow: none;
  &:hover {
    background-color: #434c57;
    cursor: pointer;
  }
}

.custom-insurance-company-container {
  height: 70px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  gap: 10px;
  label {
    color: rgba(50, 64, 80, 1);
    font-weight: 600;
    font-size: smaller;
  }
}

.collision-container{
  margin-top: 14px;
}

.collision-responsible-container{
  height: 70px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  gap: 10px;
  label {
    color: rgba(50, 64, 80, 1);
    font-weight: 600;
    font-size: smaller;
  }
}

.collision-warning {
  display: block;
  font-style: italic;
  color: black;
  font-size: x-small;
  text-align: start;
  margin-top: 0;
}

.insurance-company-select {
  border-radius: 5px;
  border: 1px solid rgba($color-dark, 0.1);
  padding: 5px;
  font-family: "Open Sans";
  font-weight: 400;
  height: 30px;
  width: 300px;
}

.custom-insurance-company-input {
  margin-bottom: -15px;
  border: 1px solid rgba($color-dark, 0.1);
}

.attached-files-tags-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.attached-file-tag {
  background-color: #e8ecf0;
  padding: 5px;
  border-radius: 5px;
  span {
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
}

.questions-error-message {
  display: block;
  color: red;
  font-size: small;
}

.legal-text{
  font-style: italic;
}

.bank-input-wr{
  p {
    margin-bottom: 4px;
  }
}

.horizontal-divider {
  width: 100%;
  color: rgb(50,64,80);
  margin: 20px 0 20px 0;
  opacity: 0.2;
}

.bank-info{
  margin-bottom: 0;
}

.bold{
font-weight: bold;
opacity: 0.9;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 700px;
  margin-top: 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}