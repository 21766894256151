.invoice-card {
  background-color: $color-invoice-background-color;
  margin-bottom: 20px;
  @include media(phone) {
    width: 100%;
  }
}

.invoice-card h2 {
  color: $color-secondary-heading;
  margin-bottom: 20px;
  width: calc(100% - 40px) !important;
  padding: 0 20px;
  @include media(phone) {
    width: calc(100% - 20px) !important;
  }
}

.invoice-card h2 > span {
  margin-left: 5px;
  font-size: inherit;
  line-height: 35px;
  font-weight: 300;
}

.personal-data {
  width: calc(100% - 40px) !important;
  padding: 0 20px;
  @include media(phone) {
    width: calc(100% - 20px) !important;
  }
}

.insurance-data > div {
  width: calc(100% - 40px) !important;
  padding: 0 20px;
}

.insurance-name-row {
  background-color: #e2f0ff;
}

.insurance-name-row:nth-of-type(odd) {
  background-color: #f8fbff;
}

.insurance-data .row-container {
  margin-bottom: 5px;
  margin-top: 5px;
}

.ocr-row {
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}

.insurance-data .label-container {
  width: 50%;
  margin-bottom: 0px;
}
