.color-gold {
  color: $color-light-gold;
}
.gold-divider {
  background-color: $color-light-gold;
}
.color-white {
  color: $color-white;
}
.color-red {
  color: red;
}

.color-green {
  color: green;
}

.color-blue {
  color: #324050;
}

.uppercase {
  text-transform: uppercase;
}
.gold-icon {
  color: $color-light-gold;
}
.background-cover {
  background-size: cover;
}

.background-center {
  background-position: center;
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.flex {
  @include flex;
}

.flex.row {
  @include media(phone) {
    flex-direction: row;
  }
}

.flex.column {
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-evenly {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}

.align-center,
.align-center.row,
.align-center.flex {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.center {
  margin: 0 auto;
}

.label-bold {
  font-weight: 600;
  color: $color-primary-label;
}

.label-grey {
  color: #6b6b6b;
}

.text-normal {
  @include font-normal(16px);
  color: $color-secondary-text;
}

.button,
.button span {
  @extend .flex;
  @extend .align-center;
  @extend .justify-center;
  @include font-normal(12px);
  @include button;
  @extend .upper-case;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
}
.button span {
  max-width: 100%;
}
span i {
  margin: 0 10px;
}
.align-text-left {
  text-align: left;
}
.align-text-right {
  text-align: right;
}
.align-text-center {
  text-align: center;
}
.align-text-center,
.align-text-right {
  @include media(phone) {
    text-align: left;
  }
}

.align-always-center {
  text-align: center;
  width: 100%;
}

.align-always-right {
  text-align: right;
  width: 100%;
}
.no-text-wrap {
  white-space: nowrap;
}
.upper-case {
  text-align: center;
  text-transform: uppercase;
}
.container-width {
  max-width: 960px;
}
.only-mobile,
.only-mobile.fa,
.only-mobile.fas,
.only-mobile.fal,
.only-mobile.fab,
.only-mobile.far {
  display: none;
  @include media(phone) {
    display: block;
  }
}

.row-container {
  @extend .flex;
  @extend .justify-between;
  margin-bottom: 30px;
  @include media(phone) {
    margin-bottom: 0;
  }
}

.label-container {
  @extend .flex;
  @extend .flex, .column;
}
.label-container .content,
.label-container .content span {
  color: $color-secondary-text;
  font-size: 16px;
}
.label-container .content-primary {
  color: $color-primary-text;
  font-size: 16px;
}

.sub-heading-content-list {
  margin: 0px;
  width: 100%;

  @include media(phone) {
    margin: 0px;
    width: 100%;
  }
}

.letter-spacing {
  letter-spacing: 0.7px;
}
.no-white-space {
  white-space: nowrap;
}
.button-span {
  text-align: left !important;
  text-transform: none !important;
}
.button-icon {
  font-size: 18px;
}
.hide-at-menu-break {
  @media screen and (max-width: $break-menu) {
    display: none !important;
  }
}