.user-details .label-container {
  margin-bottom: 15px;
  justify-content: flex-start;
  flex-grow: 0;
}

form.details-form {
  .button {
    margin-top: 30px;
    @include media(phone) {
      align-self: center !important;
    }
  }
  .flex-grow:first-child {
    @include media(tablet) {
      max-width: 100% !important;
    }
  }
  .form-label:first-child {
    font-weight: 600;
    color: #4f555c;
    font-size: 14px;
    line-height: 26px;
    max-width: 250px;
  }
  @media only screen and (max-width: 600px) {
    .mobile-input-full-length {
      max-width: 600px !important;
      width: 100% !important;

      input {
        font-size: 16px;
      }
    }
    .form-label:first-child {
      max-width: 100%;
    }
  }
}
