.customer-greeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 770px;
  p {
    text-align: center;
    max-width: 770px;
    font-size: 14px;
  }
  a {
    font-size: 14px;
  }
}

.customer-greeting-title {
  color: black !important;
  font-size: 16px;
  font-weight: bold;
}

