.container.list ul {
  @extend .sub-heading-content-list;
}

.content-space li {
  margin-right: 0px;
  width: 30%;
  @include media(phone) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.content-space li:nth-child(3n + 2) {
  margin-right: 5%;
  margin-left: 5%;
  @include media(phone) {
    margin-right: 0%;
    margin-left: 0%;
  }
}

.insurance-promotion-banner {
  background-color: #cfe2d4;
  height: 120px;
  max-width: 540px;
  margin: 0 auto 15px auto;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.insurance-promotion-banner i {
  color: #5c8c68;
  font-size: 145px;
  position: absolute;
  left: -60px;
  top: -30px;
  opacity: 0.25;
}

@media only screen and (max-width: 600px) {
  .insurance-promotion-banner {
    margin-left: auto;
    margin-right: auto;
  }
}

.insurance-promotion-text-container {
  width: 285px;
  margin: 0 auto;
}

.insurance-promotion-header {
  font-family: 'Open Sans', sans-serif;
  font-size: 23px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #446a4b;
  margin: 10px 0 10px 0;
}

.insurance-promotion-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #446a4b;
  margin: 0;
}
