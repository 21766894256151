.shop-content {
  max-width: 560px;
  margin-top: -7px;
}

.user-row input {
  height: 29px;
}

.terms {
  span {
    font-weight: 200 !important;
    font-size: 12px !important;
    a {
      text-decoration: underline !important;
      font-weight: 200 !important;
    }
  }
}
