.full-height {
  height: unset !important;
  margin-right: 0px !important;
}

.input-full-length {
  flex-grow: 1;
}

.container.shop {
  .content {
    .shopContent {
      min-width: 530px;

      @include media(phone) {
        min-width: 100%;
      }
    }
    > .register {
      @include media(desktop) {
        padding-left: 20px;
      }
    }
  }

  .inner-container-register {
    background-color: #fafafa;
    position: fixed;
    right: auto;
    width: 300px;
    top: inherit;

    @supports (position: sticky) {
      position: sticky;
      top: 100px;
      right: 0px;
      width: auto;
    }

    @include media(tablet) {
      position: initial;
      background-color: transparent;
    }
  }

  .combination-discount-span {
    margin-right: 12px;
  }

  button.campaign {
    height: 37px;
  }

  input.campaign-code-input {
    width: unset;
  }
}

.sign-insurance-loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.7);
  z-index: 5;
}

.sign-insurance-loading > div {
  position: absolute;
  top: 250px;
}

.circle-icon {
  @include media(phone) {
    margin-right: 0px;
  }
}

.combination-discount-span {
  @include media(phone) {
    margin-right: 0px !important;
  }
}

.container.shopping-card .card.expandable-container {
  @include media(phone) {
    max-height: 40px;
  }
}

.shopcard-icon.fa-chevron-up {
  margin-left: 10px;
}
