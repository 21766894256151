// @import "../../sass/mixins.scss";
// @import "../../sass/colors.scss";

.button-primary.button {
  color: $color-primary-button-text;
  position: relative;
  transition: all 0.4s;
  background-size: 100%;
  background: $linear-gold-inverted;
  background-size: 200%;
  border: 0px;

  &:hover {
    background-position: -100%;
  }
}
.button-secondary.button {
  color: $color-primary-button-text;
  background-image: linear-gradient(
    to bottom,
    $color-button-primary-gradient-stop1,
    $color-button-primary-gradient-stop2
  );
}

.button-confirm.button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 90px;
  padding: 5px;
  color:white;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: smaller;
  font-weight: 400;
  border-radius: 5px;
  background-color: #324050;
  border: 1px solid rgba($color-dark,0.1);
  box-shadow: none;
  &:hover{
    background-color: #434c57;
    cursor: pointer;
  }
}

.button.button-co-insured {
  color: $color-white;
  position: relative;
  transition: all 0.4s;
  background-size: 100%;
  background: $linear-blue-inverted;
  background-size: 200%;
  &:hover {
    background-position: -100%;
  }
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.button.disabled-spinner:disabled {
  cursor: progress;
}
.sign-insurance {
  padding: 10px;
  max-width: 200px;
}

.btn-gray {
  color: black !important;
  background: linear-gradient(
                  45deg,
                  #727272 5%,
                  #a9a9a9 95%
  )!important;
  span {
    color: black !important;
  }
}