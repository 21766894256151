.login-form .button,
.token-form .button,
.mobile-login-content .button {
  max-width: $loginMaxWidth;
  margin: 5px 0;
}
.login-form-content,
.token-form .token-input {
  max-width: $loginMaxWidth;
  width: 100%;
}
.login-form-content {
  margin-bottom: 10px;
}

.login-form {
  .button-logo {
    position: absolute;
    right: 0;
    margin-right: 15px;
    top: 10px;
  }

  a {
    text-decoration: underline;
    margin-top: 13px;
  }
}

.mobile-login-content {
  .button {
    text-transform: none;
  }

  .button-logo {
    position: absolute;
    right: 0;
    margin-right: 15px;
    top: 10px;
  }
}
.bg-login {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-position-y: center;
  max-width: none !important;

  @include media(phone) {
    flex-direction: row !important;
    height: fit-content;
    margin-bottom: 20px;
  }
}
.bankid-qrcode-container {
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  span {
    font-size: 12px;
  }
  .left-wrapper, .right-wrapper {
    border-radius: 8px;
    z-index: 1;
    padding: 30px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
  .qr-banner { 
    position: absolute;
    top: 70px;
    height: 800px;
    left: 0;
    min-width: 1920px;
  }
  .left-wrapper {
    margin-right: 30px;
    background: #fff;
  }
  .qr-code {
    padding: 10px;
    width: 130px;
    margin: 0 auto;
    border: 2px solid orange;
    border-radius: 20px;
    margin-top: 30px;
    animation: fadeIn infinite 7.5s alternate;
  }
  .bankid-qrcode-footer {
    padding-top: 30px;
    margin-top: 20px;
    border-top: 1px solid orange;
  }

  @keyframes fadeIn {
    0% {
      border-color: #fff;
    }
    100% {
      border-color: orange;
    }
  }

  .right-wrapper {
    background: #1a3d50;
    display: flex;

    .phone {
      border: 1px solid #fff;
      margin: 0 auto;
      border-radius: 12px;

      .top-border {
        width: 55px;
        border-top: 1px solid #fff;
        margin: 25px auto 10px auto;
      }
      .bankid-logo {
        width: 100%;
      }
      .ready-to-use {
        padding: 0px 10px 20px 10px;
        line-height: 0.8;
        span {
          color: #ffd4a3;
          font-size: 10px;
        }
      }
      .qr-code-white {
        width: 35px;
      }
      .footer-qr-code {
        padding-bottom: 15px;
        span {
          color: #fff;
          font-size: 10px;
        }
      }
    }
  }
  .qr-code-loader {
    margin: 30px 0;
    position: relative;
    .qr-loading-phone {
      width: 65px;
      border: 1px solid #1a3d50;
      margin: 0 auto;
      height: 115px;
      border-radius: 8px;
      .loader-top-border {
        width: 25px;
        border-top: 1px solid gray;
        margin: 10px auto;
      }
    }
    .loading-dots-container {
      position: absolute;
      display: flex;
      width: 100%;
      bottom: 20px;

      .dots {
        margin: 0 auto;
        display: flex;
      }

      .dot-1, .dot-3, .dot-5 {
        width: 14px;
        height: 14px;
      }

      .dot-2, .dot-4 {
        width: 10px;
        height: 10px;
      }

      @keyframes showHideDot1 {
        0% { opacity: 0; }
        10% { opacity: 1;}
        20% { opacity: 1; }
        30% { opacity: 1;}
        40% { opacity: 1;}
        50% { opacity: 1; }
        60% { opacity: 1; }
        70% { opacity: 1; }
        80% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
      }

      @keyframes showHideDot2 {
        0% { opacity: 0; }
        10% { opacity: 0;}
        20% { opacity: 1; }
        30% { opacity: 1;}
        40% { opacity: 1;}
        50% { opacity: 1; }
        60% { opacity: 1; }
        70% { opacity: 1; }
        80% { opacity: 1; }
        90% { opacity: 0; }
        100% { opacity: 0; }
      }

      @keyframes showHideDot3 {
        0% { opacity: 0; }
        10% { opacity: 0;}
        20% { opacity: 0; }
        30% { opacity: 1;}
        40% { opacity: 1;}
        50% { opacity: 1; }
        60% { opacity: 1; }
        70% { opacity: 1; }
        80% { opacity: 0; }
        90% { opacity: 0; }
        100% { opacity: 0; }
      }

      @keyframes showHideDot4 {
        0% { opacity: 0; }
        10% { opacity: 0;}
        20% { opacity: 0; }
        30% { opacity: 0;}
        40% { opacity: 1;}
        50% { opacity: 1; }
        60% { opacity: 1; }
        70% { opacity: 0; }
        80% { opacity: 0; }
        90% { opacity: 0; }
        100% { opacity: 0; }
      }

      @keyframes showHideDot5 {
        0% { opacity: 0; }
        10% { opacity: 0;}
        20% { opacity: 0; }
        30% { opacity: 0;}
        40% { opacity: 0;}
        50% { opacity: 1; }
        60% { opacity: 0; }
        70% { opacity: 0; }
        80% { opacity: 0; }
        90% { opacity: 0; }
        100% { opacity: 0; }
      }
      
      .dot {
        --step-time: 5s;
        --delay-time: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        border-radius: 100px;
        background: #1a3d50;
        margin: 0 8px;
        opacity: 0;
        display: inline;
        position: relative;
        animation-duration: var(--step-time);
    
        &.dot-1 {animation-name: showHideDot1;}
        &.dot-2 {animation-name: showHideDot2;}
        &.dot-3 {animation-name: showHideDot3;}
        &.dot-4 {animation-name: showHideDot4;}
        &.dot-5 {animation-name: showHideDot5;}
      }

    }
  }
}
