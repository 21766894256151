.overlay {
  display: none;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: $menuOverlayZIndex;
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay.show-overlay {
  display: block;
}
