.modern-radio-container {
  margin: 0px 20px 0 0;
  display: flex;
  cursor: pointer;
  user-select: none;
}

.radio-outer-circle {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border: 1px solid rgba(50,64,80,0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-top: 2px;
  transition: all 0.1s linear;
  box-shadow: inset -2px -3px rgba(50,64,80,0.3);
}

.radio-inner-circle {
  margin-top: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #324050;
  transition: all 0.1s linear;
}
.unselected {
  border: 1px solid rgba(50,64,80,0.3);
}
.unselected-circle {
  width: 0;
  height: 0;
}
.helper-text {
  color: rgba(50,64,80,1);
  padding-right: 6px;
}
