$color-black: #000;
$color-dark: #181819;
$color-dark-grey: #363738;
$color-white: #fff;
$color-whitegrey: #dfe5ec;
$color-alert: red;

$color-grey-border: #d1d1d1;
$color-invoice-background: #f8f8f8;
$color-primary-active-border: #65cd7e;
$color-payment-method-background: #ececec;
$color-dark-grey-border: #979797;
$color-white-grey-background: #eeeeee;
$color-key-tab-background: #f6f6f6;
$color-primary-form-label: #747575;

// below is structured
$color-primary-heading: #1d1e1f;
$color-secondary-heading: #324050;
$color-primary-label: #4f555c;
$color-primary-link: #4f555c;
$color-secondary-text: #737a82;
$color-primary-text: #4f555c;
$color-primary-h3: #5a81ae;
$color-primary-h4: #4f555c;
$color-primary-h6: #4f555c;
$color-primary-blue: #324050;
$color-white: #fff;
$color-primary-placeholder: #747575;
$color-primary-button-text: #324050;
$linear-gold-inverted: linear-gradient(
  45deg,
  #ebb471 10%,
  #fcd7ab 50%,
  #ebb471 90%
);
$linear-blue-inverted: linear-gradient(
  45deg,
  #324050 10%,
  #72859c 50%,
  #324050 90%
);
$linear-border-gold: linear-gradient(36deg, #fcd7ab, #e48f45);
$linear-grey: linear-gradient(to left, #f6f8f9, #dfe5ec);
$linear-black: linear-gradient(206deg, #000, #474c51);
$color-button-primary-gradient-stop1: #fddab2;
$color-button-primary-gradient-stop2: #f1bf92;
$color-button-co-insured-gradient-stop1: #72859c;
$color-button-co-insured-gradient-stop2: #324050;
$color-light-gold: #ffd4a3;
$color-read-more-card: #f2c398;
$color-insurance-divider: #d5d5d5;
$color-insurance-terms: #7993b0;
$color-primary-card-background: #f8f8f8;
$color-co-insured-border: #7296a0;
$color-un-co-insured-border: #ff9f9f;
$color-banner-primary-background: #fff7f0;
$color-modal-background: #324050;
$color-snackbar-error-background: #f8d7da;
$color-snackbar-error-icon: #9f545b;
$color-snackbar-error-text: #772229;
$color-snackbar-success-background: #d4edda;
$color-snackbar-success-icon: #459656;
$color-snackbar-success-text: #165724;
$color-invoice-background-color: #f3f9ff;
$color-hamburger-background-stop1: #000000;
$color-hamburger-background-stop2: #474c51;
$color-insurance-card-simple-background: #f6f8f9;
