.row-container {
  @include media(phone) {
    justify-content: center;
  }
}

.label-container {
  @include media(phone) {
    margin-bottom: 20px;
  }
}

.co-insured-border .label-container,
.un-co-insured-border .label-container {
  margin-bottom: 0px;
}

.co-insured-border .label-container:nth-child(2) {
  @include media(phone) {
    display: none;
  }
}

.co-insured-border .label-container:last-child {
  @include media(phone) {
    align-items: flex-end;
  }
}

.co-insured-header {
  margin-bottom: 10px;
}
