.read-more-card.flex {
  width: 30%;
}

.read-more-card a {
  border-radius: 2px;
  transition: background-color ease-in-out 0.2s;
  background-color: $color-read-more-card;
}

.read-more-card:hover a {
  background-color: #f2c798;
}
.read-more-card[class$="benefit"] a {
  height: $benefitCardHeight;
}
.read-more-card[class$="magazine"] a {
  height: $magazineCardHeight;
  @include media(phone) {
    width: 100%;
  }
}

.read-more-card span {
  margin-top: 8px;
  align-self: center;
}

.magazines .read-more-card span {
  margin-top: 10px;
}

.read-more-card {
  img {
    width: 37%;
    transition: transform ease-in-out 0.2s;
  }
}

.read-more-card:hover {
  img {
    transform: translateX(10px);
  }
}

.read-more-card {
  @include media(phone) {
    .read-more-link {
      font-size: 12px;
    }
  }
}
