@use "sass:math";
@import "./variables";
@import "./mixins";
@import "./colors";
@import "./helper";
@import "./fonts";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/brands.scss";
@import "./fontawesome/light.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/regular.scss";
@import "./fontello/animation.scss";
@import "./fontello/fontello-codes.scss";
@import "./fontello/fontello-embedded.scss";
@import "./fontello/fontello-ie7-codes.scss";
@import "./fontello/fontello-ie7.scss";
@import "./fontello/fontello.scss";
// below is structured

/* overrides the IE fix for public sign-insurance */
.receipt-container {
  width: 100% !important;
}

body {
  overflow-x: hidden;
}
body,
html {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
[class^="Mui"] {
  font-family: "Open Sans" !important;
}
.body {
  position: relative;
  @include media(tablet) {
    overflow-x: hidden;
  }
}

h1 {
  @include font-heading(40px);
  text-align: center;
  font-weight: 100;
  margin-bottom: 15px;
  margin-top: 0px;
}

h2 {
  @include font-heading(30px);
  color: $color-light-gold;
  margin-bottom: 0px;
  margin-top: 20px;
  @extend .upper-case;
  font-family: "SF Pro Text";
  @include media(tablet) {
    margin-bottom: 20px;
    text-align: center;
  }
}

h3 {
  @include font-heading(20px);
  color: $color-primary-h3;
  @extend .upper-case;
  font-family: "Open Sans";
  text-align: left;
  margin-bottom: 0px;
}

h4 {
  @include font-heading(22px);
  color: $color-primary-h4;
  font-family: "Open Sans";
  margin: 0;
}

h5 {
  @include font-heading(16px);
  color: $color-white;
  margin-top: 20px;
  font-family: "SF Pro Text";
}

h6 {
  @include font-heading(16px);
  color: $color-primary-h6;
  margin: 20px 0 8px 0;
  font-family: "Open Sans";
}

hr {
  width: 100%;
  max-width: 960px;
  height: 1px;
  background-color: $color-dark-grey;
  margin: 30px auto;
  border: none;
}

hr[class$="divider"] {
  @include media(phone) {
    margin-top: 10px;
  }
}

semi-bold,
.semi-bold {
  font-weight: 600;
}

a {
  @include a($color-primary-link);
}

.gold-underscore {
  border-bottom: 2px solid $color-light-gold;
  text-decoration: none;
  color: $color-primary-link;
}

.read-more-link {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2px;
}

span {
  @include span($color-primary-text);
}

p {
  @include span($color-primary-text);
}

.fa-chevron-right {
  font-size: 14px;
  font-weight: 400;
}

.container {
  @include container;
  @include media(small-desktop) {
    flex-direction: column;
  }
}
.container.row {
  @include media(small-desktop) {
    flex-direction: row;
  }
  @include media(phone) {
    flex-direction: column;
  }
}

.container.main {
  padding: ($largeNavbarHeight + 40px) 25px 90px;
  min-height: calc(100vh - #{$footerHeight});
  max-width: 1330px;
  margin-top: 30px;
  @include media(tablet) {
    padding: 0;
  }
}

ul {
  padding: 0px;
  list-style: none;
}

li {
  @include font-normal(12px);
  color: $color-primary-text;
}

i,
div {
  border: none;
  outline: none;
  // Makes all text in the app non clickable
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type="file"] {
  display: none;
}

main.container {
  max-width: 770px;
  padding: 0 40px;
  width: 100%;
  @include media(tablet) {
    padding: 50px 40px;
    margin-top: $largeNavbarHeight;
  }
  @include media(phone) {
    margin-top: $smallNavbarHeight;
    padding: 0 10px 40px 10px;
  }
  &.zero-padding {
    max-width: 100%;
    @include media(only-small-desktop) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &.zero-padding-grey {
    max-width: 100%;
    @include media(only-small-desktop) {
      padding-left: 0px;
      padding-right: 0px;
    }
    @include media(phone) {
      background-color: #f6f6f6;
    }
  }
}

span.sub-header {
  margin-bottom: 10px;
}

.card-rich-text > a {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #4f555c;
  padding-top: 20px;
  align-self: center;
}

.rich-text {
  line-height: normal;
  font-weight: 200;
  strong {
    font-weight: bolder;
  }
  li {
    font-size: 16px;
    font-weight: 200;
  }
  p {
    font-size: 16px;
    font-weight: 200;
  }
  em {
    font-size: 12px;
    font-weight: 400;
  }
  em a {
    font-style: italic;
  }
  a:not(.button) {
    border-bottom: 2px solid #ffd58d;
    text-decoration: none;
    transition: all 0.1s;
    color: #4f555c;
  }
  a:not(.button) {
    text-decoration: none;
    transition: all 0.1s;
    text-align: center;
  }
  li:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    font-weight: 600;
    margin: 0 16px 0 0;
    color: $color-light-gold;
    position: relative;
    position: -13px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fcd7ab),
      to(#efb988)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
// helpers

@import "../components/helper/button.style";
@import "../components/snackbar/snackbar.style";
@import "../components/helper/listComponent.style";
@import "../components/helper/heading.style";
@import "../components/helper/input.style";
@import "../components/helper/readMoreCard.style";
@import "../components/helper/rowContainer.style";
@import "../components/helper/carousel.style";
@import "../components/modal/modalComponent.style";
@import "../components/overlay/overlay.style";
@import "../components/helper/radioInput.style";

// claim
@import "../components/claim/VehicleClaimCard.style";

// navbar
@import "../components/navbar/topHeader.style";
@import "../components/navbar/sidebar.style";
@import "../components/navbar/hamburgerMenu.style";
@import "../components/navbar/primaryNav.style";

// login
@import "../components/login/login.style";

// start
@import "../components/info-card/infoCard.style";
@import "../components/insurance/insuranceCard.style";
@import "../components/insurance/vehicleCard.style";
@import "../components/insurance/vehicles.style";

//banner
@import "../components/banner/direct-banner.style";
@import "../components/banner/banner.style";

//benefits
@import "../components/benefit/benefitCard.style";

//formCard
@import "../components/formCard/formCard.style";

//magazine
@import "../components/magazine/magazineCard.style";

//invoice
@import "../components/invoice/invoiceCard.style";
@import "../components/invoice/keyTab.style";
@import "../components/invoice/paymentCard.style";
@import "../components/invoice/paymentMethod.style";

//shop
@import "../components/shop/shopContent.style";
@import "../components/shop/shopList.style";

//footer
@import "../components/footer/footer.style";

//Views
@import "../Views/LoginPage/style";
@import "../Views/Claim/index.style";
@import "../Views/CustomerService/index.style";
@import "../Views/Details/detailsForm.style";
@import "../Views/Details/index.style";
@import "../Views/InvoicePage/invoices-style";
@import "../Views/ShopPage/index.style";
@import "../components/attachments/addAttachments.style.scss";
