.key-tab-content {
  @extend .sub-heading-content-list;
}

.add-more-key-tabs .label-container:last-child {
  align-items: flex-end;
  @include media(phone) {
    align-items: center;
    max-width: 100%;
  }
}

.key-tab-header.flex {
  width: fit-content;
}

.key-tab-header .info-text-help {
  margin: 0 0 0 6px;
}

.key-tab {
  .info-text-help-link {
    opacity: 1 !important;
  }

  .info-text-icon {
    color: #51657b;
    font-weight: 400;
    font-size: 15px;

    @include media(phone) {
      margin-bottom: 5px;
    }
  }

  .info-text-help-container {
    width: 206px;
    padding: 15px 24px;
    z-index: 100;
  }

  button {
    @include media(phone) {
      max-width: 100%;
    }
  }
}
