@font-face {
  font-family: "SF Pro Display";
  src: url("../assets/fonts/sfpro/SFProDisplay-Thin.woff2") format("woff2"),
    url("../assets/fonts/sfpro/SFProDisplay-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../assets/fonts/sfpro/SFProText-Regular.ttf") format("ttf"),
    url("../assets/fonts/sfpro/SFProText-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../assets/fonts/sfpro/SFProText-Regular.woff") format("woff"),
    url("../assets/fonts/sfpro/SFProText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
