.heading {
  margin-top: 0px;
  margin-bottom: 13px;
  text-transform: uppercase;

  .border {
    width: 246px;
    background-color: $color-light-gold;
    height: 2px;
    margin-bottom: 20px;
    @include media(phone) {
      max-width: 246px;
      min-width: 200px;
    }
  }
  &.secondary {
    margin-bottom: 15px;
  }
}

.secondary-heading {
  margin-bottom: 6px;
  margin-left: -6px;
}
