.payment-card-container-notchosen.disabled-option {
  display: none;
}

.payment-radio:after {
  width: 14px !important;
  height: 14px !important;
}

.invoices.loading {
  position: absolute;
  top: 100px;
  left: 0px;
}

.update-button {
  display: flex;
  justify-content: flex-end;
  @include media(phone) {
    justify-content: center;
  }
}

.direct-debit-banner {
  @include media(phone) {
    h3 {
      line-height: 1.5;
    }
    margin: 0 -30px 0 -30px;
    width: calc(100% + 60px);
  }
}

.direct-debit-text {
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4f555c;
}

.invoice-payment-section {
  margin-bottom: 40px;
}

.disabled-option {
  opacity: 0.4;
  cursor: default !important;
  background-color: #fff;
}

.payment-period-help-text {
  font-family: "SF Pro Text", "Arial";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f555c;
}

@media(max-width: 600px) {
  .invoice-list {
    align-items: center;
  }

  .invoice-list-element {
    width: 90%;
  }
}
