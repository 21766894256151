header {
  width: 100%;
  z-index: 2000;
  position: fixed;
  top: 0;
}

header #top-bar {
  width: 100%;
  height: $largeNavbarHeight;
  background: $color-black;
  z-index: $topHeaderZIndex;
  @include media(phone) {
    height: $smallNavbarHeight;
  }
}

#top-bar .container {
  padding: 0px 20px;
  max-width: 1330px;

  &.new-top-bar {
    padding: 0px;
    padding-left: 20px;
    max-width: 960px;
  }
}

#top-bar .site-logo,
#top-bar img {
  width: 120px;
  margin-left: 15px;
  @include media(tablet) {
    margin-left: 0;
  }
}

header .nav-link {
  font-weight: 300;
  margin: 0 10px;
  @include media(break-menu-screen) {
    display: flex;
  }
}

header .nav-link.isLogin {
  display: block;
  margin: 0;
  @include media(phone) {
    font-size: 11px;
    margin: 0;
  }
}

.nav-link i {
  font-size: 18px;
  margin-left: 1rem;
  margin-right: 0;
  font-weight: 600;
  width: 20px;
}

.nav-golden-link {
  color: $color-light-gold;

  &.underlined {
    text-decoration: underline;
    text-underline-position: under;

    @include media(tablet) {
      text-decoration: none;
    }
  }

  @include media(tablet) {
    display: flex;
    flex-direction: column;
  }

  i {
    text-underline-position: initial;
    margin-right: 5px;
    font-weight: 300;
    font-size: 13px;

    @include media(tablet) {
      margin-bottom: 0.3rem;
    }
  }
}

#top-bar .container.row {
  flex-direction: row;
}
