$benefitCardHeight: 130px;
$cardWidth: 100%;
$footerHeight: 122px;
$hamburgerWidth: 90%;
$hamburgerZIndex: 401;
$inputMarginBottom: 10px;
$largeNavbarHeight: 70px;
$loginMaxWidth: 300px;
$magazineCardHeight: 285px;
$menuOverlayZIndex: 101;
$sidebarWidth: 220px;
$smallNavbarHeight: 60px;
$topHeaderZIndex: 100;
