[class^="icon-"],
[class*=" icon-"] {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;

  /* fix buttons height */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
}

.icon-boat {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe800;&nbsp;"
  );
}
.icon-car {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe801;&nbsp;"
  );
}
.icon-trailer {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe802;&nbsp;"
  );
}
.icon-motorbike {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe803;&nbsp;"
  );
}
.icon-motorhome {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe804;&nbsp;"
  );
}
.icon-housetrailer {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe805;&nbsp;"
  );
}
.icon-truck {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe806;&nbsp;"
  );
}
.icon-veterancar {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe807;&nbsp;"
  );
}
.icon-keys {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe808;&nbsp;"
  );
}
.icon-percentage {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe80b;&nbsp;"
  );
}
.icon-autogiro {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe80c;&nbsp;"
  );
}
.icon-card {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe80d;&nbsp;"
  );
}
.icon-invoice {
  *zoom: expression(
    this.runtimeStyle[ "zoom" ] = "1",
    this.innerHTML = "&#xe80e;&nbsp;"
  );
}
