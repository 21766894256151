.ab-error {
    margin-top: 20px;
    padding: 25px 0;
    border-radius: 10px;
    background-color: white;
    border: 2px solid #ffd4a3;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ab-error h1 {
    font-size: 2em !important;
    letter-spacing: 0.2rem !important;
    padding-top: 10px !important;
    color: #1d1e1f !important;
}

.ab-error p {
    font-size: 12px;
    margin-bottom: 10px;
    padding: 0 55px;
    color: #1d1e1f;
}

.ab-error-phone {
    margin-top: 20px;
    padding: 0 15px;
    border-radius: 10px;
    border: 1px solid #c7cace;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ab-error-phone h1 {
    font-size: 1.5em !important;
    letter-spacing: 0.11rem !important;
    padding-top: 10px !important;
    color: black !important;
}
