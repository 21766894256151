.container.paymentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 160px;
  width: 216px;
  padding: 10px 20px;
  background-color: $color-payment-method-background;
  @include media(tablet) {
    margin-bottom: 15px;
  }
  &.active {
    border: 1px solid $color-primary-active-border;
    opacity: 1;
  }
  opacity: 0.5;
  h3 {
    margin-top: 0;
  }
  span {
    text-align: center;
    font-size: 15px;
  }
  .card.content {
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
  }
}
