.modal-body {
  display: flex;
  flex-direction: column;
}

.content-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  width: 100%;
}

.insurances-select {
  border-radius: 5px;
  border: 1px solid rgba(24, 24, 25, 0.1);
  padding: 5px;
  font-family: "Open Sans";
  font-weight: 400;
  height: 35px;
  width: 100%;
  margin-top: 10px
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.loading-spinner {
  color: #ffd4a3 !important;
}

.customer-input {
    border-radius: 5px;
    border: 1px solid rgba(24, 24, 25, 0.1);
    font-family: "Open Sans";
    font-weight: 400;
    height: 35px;
    width: 100%;
    margin-top: 10px
}

.input-labels {
    color: white;
    font-family: "Open Sans";
    font-size: 14px;
}

.insurance-list-wrapper {
  list-style-type: circle !important;
}

.insurance-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;
}

.icon-dot {
  width: 5px;
  border-radius: 50%;
  height: 5px;
  border: 1px solid white;
  background-color: white;
  margin-right: 10px;
}

.divider {
  width: 100%;
  border-bottom: 1px solid gray;
}

.end-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}