footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: -1px;
  padding: 30px 0;
  background: $color-dark;
  position: relative;
  a {
    font-size: 16px;
  }
  li {
    color: $color-white;
  }
  h5 {
    margin: 20px 0 0 0;
  }
}
.site-footer:first-child {
  padding: 0 20px;
}

.footer-mobile {
  span {
    font-weight: 300;
  }
  a {
    size: 14px;
  }
}

.contact-us {
  h2 {
    font-weight: 200;
  }
  @include media(desktop) {
    &.flex {
      width: auto;
    }
  }
  @include media(tablet) {
    margin: 0 auto 20px auto;
  }

  &.icon-row {
    width: 440px;
    @include media(phone) {
      width: 100%;
    }
  }
  &.icon-column {
    margin-right: 40px;
    font-weight: 100;
    span {
      margin-top: 10px;
      font-weight: 200;
      font-size: 16px;
    }
    span i {
      margin-left: 5px;
    }
    @include media(tablet) {
      margin: 0px;
    }
  }
  a {
    font-weight: 200;
    line-height: 20px;
  }
}

.icon-column.flex {
  width: auto;
  @include media(tablet) {
    width: 50%;
  }
}

.site-info-column {
  margin-right: 20px;
  &.flex {
    width: 220px;
  }

  @include media(small-desktop) {
    &.align-start {
      align-items: center;
    }
    text-align: center;
  }

  @include media(tablet) {
    &.flex {
      width: 50%;
    }
    margin: 0px;
  }
}

.social-media ul.flex.row {
  margin: 0;
  width: 30%;
  max-width: 250px;

  i {
    font-weight: 100;
    font-size: 28px;
  }
  @include media(tablet) {
    width: 100%;
  }
}

.footer-links-content {
  @include media(tablet) {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .footer-links {
    margin-right: 0px;
    color: $color-white;
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.img-divider {
  width: 100%;
  display: block;
  position: absolute;
  top: -40px;
  left: 0;
  height: 60px;
}

.company-info-list li {
  font-weight: 200;
}
