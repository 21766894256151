.magazine-image {
  width: $cardWidth;
  min-width: $cardWidth;
  height: $magazineCardHeight;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  @include media(phone) {
    min-width: unset;
    width: 100%;
  }
}

.magazine-card a.flex {
  width: auto;
  flex-direction: row;
}

.magazine-card i {
  font-size: 10px;
  margin: 1px 0 0 5px;
}

@include media(phone) {
  .magazine-card a {
    width: 100%;
  }
}

.magazines li {
  margin-bottom: 20px;
}

.magazine-card .content-info {
  margin-top: 5px;
  &.flex {
    flex-direction: row;
  }
}

.read-more-card .align-text-center {
  text-align: center;
}

.magazine-border {
  position: relative;
  background-color: $color-read-more-card;
  height: 8px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  top: -8px;
}

.container.magazines li {
  @include media(phone) {
    display: none;
    &:first-child,
    &:last-child {
      display: flex;
      margin: 0 5px;
    }
    &:first-child {
      width: 70%;
    }
    &:last-child {
      width: 30%;
    }
  }
}

.container.magazines ul {
  @include media(phone) {
    &.list.row {
      flex-wrap: nowrap;
    }
  }
}

.magazine-card {
  @include media(phone) {
    .read-more-link {
      font-size: 12px;
    }
  }
}
