.snackbar {
  border-radius: 5px;
  height: 60px;
  margin: 0 auto;
  max-width: 690px;
  top: 5px !important;
  width: 100%;
  @include media(tablet) {
    width: auto;
  }
  @include media(phone) {
    top: $smallNavbarHeight !important;
    width: 100%;
  }
}

.snackbar-content {
  span.snackbar-title {
    font-weight: bold;
    padding: 12px 0px 0px 0px;
  }

  span.snackbar-message {
    padding: 6px 8px 8px 0px;
  }
}

.snackbar-content i {
  cursor: pointer;
  font-size: 20px;
}

.snackbar-content {
  border-radius: 5px;
  height: 10px; //IE11
  min-height: 60px;
  padding: 5px 15px;
}

.failure {
  background-color: $color-snackbar-error-background;
  color: $color-snackbar-error-text;
  i {
    color: $color-snackbar-error-icon;
  }
}

.success {
  background-color: $color-snackbar-success-background;
  color: $color-snackbar-success-text;
  i {
    color: $color-snackbar-success-icon;
  }
}
