.vehicle-claim-card {
  position: relative;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &:hover {
    border: 1px solid rgba(50, 64, 80, 0.1);
    border-radius: 5px;
    cursor: pointer;
  }

  div i {
    width: 100%;
    height: 80px;
    font-size: 100px;
  }

  div i::before {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.vehicle-claim-background-image {
  display: flex;
  align-content: center;
  justify-items: center;
}

.vehicle-claim-add-new-vehicle-symbol{
  font-size: 45px;
  height: 60px;
  padding-top: 20px;
  margin: auto;
}

.vehicle-claim-additional-info{
  position: absolute;
  top:119px;
  left: 33px;
  font-size: x-small;
  font-style: italic;
  opacity: 50%;
}

.selected {
  border: 2px solid rgba(50, 64, 80, 0.5);
  border-radius: 5px;
  &:hover {
    border: 2px solid rgba(50, 64, 80, 0.5);
    border-radius: 5px;
    cursor: auto;
  }
}

.vehicle-claim-regnr {
  text-align: center;
  font-size: 15px;
  margin: 0;
  font-family: "SF Pro Text";
}
