.banner.flex {
  width: 100%;
}

.banner {
  background-color: $color-banner-primary-background;
  margin-bottom: 50px;
  @include media(phone) {
    margin-bottom: 30px;
  }
}
.banner-content {
  padding: 0 20px;
  @include media(phone) {
    order: 2;
  }
}
.banner span {
  width: 100%;
  @include media(phone) {
    width: 80%;
  }
}

.banner i {
  font-size: 70px;
  margin-right: 20px;
  object-fit: contain;
  @include media(phone) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    order: 1;
  }
}

.children-content {
  margin: 30px 0 20px 170px;
  @include media(phone) {
    margin: 30px 0 20px 0;
  }
}

.vertical-promotional-banner {
  display: none;
  @include media(xl-screen) {
    display: block;
  }
}
.vertical-promotional-banner-mobile{
  display: none;
  @include media(phone){
    display: block;
    width: 100%;
  }
}

.vertical-promotional-banner-mobile .container{
  display: none;
  @include media(phone) {
    display: block;
    width: 270px;
  }
}
.vertical-promotional-banner .container {
  display: none;
  position: sticky;
  top: 80px;
  width: 270px;
  @include media(xl-screen) {
    display: block;
  }
}

.vertical-promotional-banner-mobile .content,
.vertical-promotional-banner .content {
  height: 100%;
  justify-content: center;
  min-height: 360px;
  padding: 8px;
  width: calc(100% - 16px);

  i {
    display: none;
  }
}

.horizontal-promotional-banner {
  margin: 40px 0;
  @include media(phone) {
    display: none;
  }
}

.horizontal-promotional-banner {
  img {
    width: 100%;
    height: auto;
  }
}

.information-banner .container {
  @include media(phone) {
    height: $smallNavbarHeight;
  }
}

.information-banner .content {
  border-radius: 2px;
  height: 100%;
  padding: 6px 8px;
  width: calc(100% - 16px);

  i {
    color: #eca700;
    cursor: pointer;
    padding: 4px;
  }
  .span {
    padding-bottom: 20px;
  }
}

.information-banner.information-snackbar {
  @extend .fixed;
  top: 70px;
  @media only screen and (max-width: 600px) {
    top: $smallNavbarHeight;
  }
  width: 100%;

  @include media(break-menu-screen) {
    display: none;
  }
}
